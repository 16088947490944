import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/theme/layout'
import Footer from '../components/theme/footer-landing'
import SEO from '../components/seo'
import {graphql, useStaticQuery, Link} from "gatsby";
import {get} from "lodash";
import axios from "axios";
import styles from '../scss/page.scss'
import Carousel from "react-bootstrap/Carousel";
import {getHeaderSize, handleInnerNavigation, productUrl, triggerEvent} from '../helpers'

class TypesOfCbdOils extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Types Of CBD Oils | Honest Paws®'

    this.imageCalmCbdOil = this.props.data.imageCalmCbdOil.childImageSharp.fluid
    this.imageMobilityCbdOil = this.props.data.imageMobilityCbdOil.childImageSharp.fluid
    this.imageReliefCbdOil = this.props.data.imageReliefCbdOil.childImageSharp.fluid
    this.imageWellnessCbdOil = this.props.data.imageWellnessCbdOil.childImageSharp.fluid
    this.imageWellnessCbdOilExtraStrength = this.props.data.imageWellnessCbdOilExtraStrength.childImageSharp.fluid

    this.seo = {
      children: [],
      meta: {
        title: title
      },
      title: title,
      disallowIndexing: true
    }

    this.setCurrentProduct = this.setCurrentProduct.bind(this)
    this.showGladlyChat = this.showGladlyChat.bind(this)

    this.state = {
      currentProduct: {},
      headerHeight: 0,
      products: [
        {
          description: 'Mobility CBD oil was carefully formulated to support dogs experiencing occasional joint stiffness.',
          handle: 'extra-strength-mobility-tincture',
          image: '/img/landing-pages/types-of-cbd-oil/mobility-main-img.svg',
          imageMobile: '/img/landing-pages/types-of-cbd-oil/M-mobility-main-img.png',
          title: 'Mobility',
        },
        {
          description: 'Calm CBD oil was carefully formulated to support dogs exhibiting nervousness or hyperactivity.',
          handle: 'extra-strength-calm-tincture',
          image: '/img/landing-pages/types-of-cbd-oil/calm-main-img.svg',
          imageMobile: '/img/landing-pages/types-of-cbd-oil/M-calm-main-img.png',
          title: 'Calm',
        },
        {
          description: 'Well CBD oil is perfect for pets of any age as it helps prevents issues associated with the aging process.',
          handle: 'extra-strength-well-tincture',
          image: '/img/landing-pages/types-of-cbd-oil/well-new-main-img-vert2.png',
          imageMobile: '/img/landing-pages/types-of-cbd-oil/well-new-main-img-vert2.png',
          title: 'Well',
        }
      ]
    }

  }

  showGladlyChat() {
    triggerEvent('gladly.open', {})
  }

  setCurrentProduct(handle) {
    this.state.products.map((product) => {
      if (product.handle === handle) {
        this.setState({
          currentProduct: product
        })
      }
    })
  }

  componentDidMount() {
    this.setCurrentProduct('extra-strength-mobility-tincture')
    this.setState({
      headerHeight: getHeaderSize().height
    })
  }

  render() {
    const footer = (<Footer />)
    const {data} = this.props
    const menu = ''
    return (
      <Layout containerClassName={`page page-types-of-cbd-oils`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>

        <section className="main-section" id="products-section">
          <div className="container">
            <div className="row">

              <div className="col-12 col-md-6">
                <h1 className="text-left">Types of CBD Oil</h1>
                <h2 className="text-left pt-xl-4 pt-1">In order to better serve you and your furry friend, we now offer ailment-specific CBD  oils. Learn more about your options below!</h2>
                <hr className="hr-gray"/>
                <h3 className="pt-5">1000 MG CBD Oil Now Available</h3>
                <div className="subtitle mt-4">Perfect for extra large dogs or extra-large issues.</div>
                <div className="d-md-block d-none">{this.state.currentProduct.description}</div>
                <div className="subtitle mt-4 mb-2">1000 MG now available for:</div>
                { this.state.products.map((product, index) => {
                  return (
                    <button key={index} onClick={(e) => { e.preventDefault(); this.setCurrentProduct(product.handle) }} className={`btn mr-2 rounded p-0 ${product.handle === this.state.currentProduct.handle ? "btn-primary" : "btn-outline-custom"}`}>{product.title}</button>
                  )
                })}
                <div className="all-dog-sizes text-primary my-3">For All Dog Sizes</div>
                <a target="_top" href={`https://www.honestpaws.com/products/${this.state.currentProduct.handle}`} className="btn btn-primary mr-2 rounded btn-block">View Product</a>
                <div className="d-md-none my-4">{this.state.currentProduct.description}</div>
              </div>

              <div className="col-8 offset-2 col-md-6 offset-md-0 col-lg-5 offset-lg-1 current-product-image">
                <img src={this.state.currentProduct.image} alt={this.state.currentProduct.title} className="d-none d-lg-block"/>
                <img src={this.state.currentProduct.imageMobile} alt={this.state.currentProduct.title} className="d-lg-none responsive-img"/>
              </div>

            </div>
          </div>
        </section>

        <section className="products-section">
          <div className="container">
            <div className="row">

              <div className="col-12 col-md-6">
                <img src="/img/landing-pages/types-of-cbd-oil/D-calm-img.svg" alt="Calm CBD Oil" className="d-none d-md-none d-lg-block" />
                <img src="/img/landing-pages/types-of-cbd-oil/T-calm-img.svg" alt="Calm CBD Oil" className="d-none d-md-block d-lg-none" />
                <img src="/img/landing-pages/types-of-cbd-oil/M-calm-img.svg" alt="Calm CBD Oil" className="d-md-none" />
              </div>
              <div className="col-12 col-md-6 col-lg-4 m-auto">
                <h3>Calm CBD Oil</h3>
                <div className="subtitle">
                  Promotes relaxation in your pet. This premium product contains organic, full-spectrum hemp oil with naturally occurring CBD and chamomile.
                </div>
                <div className="mt-3">
                  <strong><p>What's Inside?</p></strong>
                  These powerful, all-natural supplements are well-known for their soothing properties. Not to mention, the terpene profile of the CBD oil was carefully formulated to support dogs exhibiting nervousness or hyperactivity.
                </div>
                <a target="_top" href="https://www.honestpaws.com/products/calm-tincture/" className="btn btn-primary rounded btn-block mt-4">View Product</a>
              </div>

              <div className="col-12 d-md-none mt-6">
                <img src="/img/landing-pages/types-of-cbd-oil/M-mobility-img.svg" alt="Mobility CBD Oil" />
              </div>
              <div className="col-12 col-md-6 col-lg-4 offset-lg-1 m-auto pt-md-5">
                <h3>Mobility CBD Oil</h3>
                <div className="subtitle">
                  Supports healthy bones and joints in your pet. This world-class oil contains organic, full-spectrum hemp oil with naturally occurring CBD and turmeric oil.
                </div>
                <div className="mt-3">
                  <strong><p>What's Inside?</p></strong>
                  Both turmeric oil and CBD oil are used all over the world to help support joint flexibility and mobility. Lastly, the terpene profile of the CBD oil was carefully formulated to support dogs experiencing occasional joint stiffness.
                </div>
                <a target="_top" href="https://www.honestpaws.com/products/mobility-tincture/" className="btn btn-primary rounded btn-block mt-4">View Product</a>
              </div>
              <div className="col-md-6 offset-lg-1 mt-4 mt-md-5 d-none d-md-block">
                <img src="/img/landing-pages/types-of-cbd-oil/D-mobility-img.svg" alt="Mobility CBD Oil" className="d-none d-md-none d-lg-block" />
                <img src="/img/landing-pages/types-of-cbd-oil/T-mobility-img.svg" alt="Mobility CBD Oil" className="d-none d-md-block d-lg-none" />
              </div>

              <div className="col-12 col-md-6 mt-6 mt-md-5">
                <img src="/img/landing-pages/types-of-cbd-oil/Well-lifestyle-img.png" alt="Well CBD Oil" />
              </div>
              <div className="col-12 col-md-6 col-lg-4 m-auto mt-md-5 m-xl-auto">
                <h3>Well CBD Oil</h3>
                <div className="subtitle">
                  Wonderful for boosting overall well in your pet. If you want to support your pet’s immune system and cognitive function, this is the oil for you
                </div>
                <div className="mt-3">
                  <strong><p>What's Inside?</p></strong>
                  This organic, full-spectrum hemp oil with naturally occurring CBD is the perfect companion for pets of any age as it helps prevent and eliminate free radicals associated with the aging process.
                </div>
                <a target="_top" href="https://www.honestpaws.com/products/well-tincture/" className="btn btn-primary rounded btn-block mt-4">View Product</a>
              </div>

              {/*<div className="col-12 d-md-none mt-6">*/}
                {/*<img src="/img/landing-pages/types-of-cbd-oil/M-relief-img.jpg" alt="Relief CBD Oil" />*/}
              {/*</div>*/}
              {/*<div className="col-12 col-md-6 col-lg-4 offset-lg-1 m-auto mt-md-5 m-xl-auto">*/}
                {/*<h3>Relief CBD Oil</h3>*/}
                {/*<div className="subtitle">*/}
                  {/*Relieves bodily discomfort in your pet. This restorative oil contains organic, full-spectrum hemp oil with naturally occurring CBD and ginger.*/}
                {/*</div>*/}
                {/*<div className="mt-3">*/}
                  {/*<strong><p>What's Inside?</p></strong>*/}
                  {/*Both ginger and CBD oil have the ability to relieve discomfort in the body. For added support, we’ve used CBD oil with a terpene profile that is well-known for keeping pets feeling their absolute best.*/}
                {/*</div>*/}
                {/*<a target="_top" href="https://www.honestpaws.com/products/relief-tincture/" className="btn btn-primary rounded btn-block mt-4">View Product</a>*/}
              {/*</div>*/}
              {/*<div className="col-md-6 offset-lg-1 mt-4 mt-md-5 d-none d-md-block">*/}
                {/*<img src="/img/landing-pages/types-of-cbd-oil/D-relief-img.jpg" alt="Relief CBD Oil" className="d-none d-md-none d-lg-block" />*/}
                {/*<img src="/img/landing-pages/types-of-cbd-oil/T-relief-img.jpg" alt="Relief CBD Oil" className="d-none d-md-block d-lg-none" />*/}
              {/*</div>*/}

            </div>
          </div>
        </section>

        <section className="contact-info bg-primary pb-0">
          <div className="container px-0 ml-0">
            <div className="row">
              <div className="col-md-6 d-none d-md-block pl-0 dog-img">
                <img src="/img/landing-pages/types-of-cbd-oil/T-dog-img.png" alt="have a question" className="d-md-block d-lg-none" />
                <img src="/img/landing-pages/types-of-cbd-oil/D-dog-img.png" alt="have a question" className="d-md-none d-lg-block" />
              </div>

              <div className="col-12 col-md-6 col-xl-4 col-lg-5 offset-lg-1 mt-3 mt-lg-6">
                <h3 className="col-12">Have a question?</h3>
                <div className="col-12">You can always chat with our team of pet nutrition experts and feel A++ about your dog’s health
                  and well. If anything changes with your dog’s health, lifestyle, or tastebuds, we’re here to
                  help make sure they have the best personal support.</div>
                <div className="col-12 col-lg-11 col-xl-10 d-flex d-md-none d-lg-flex justify-content-between mt-4">
                  <a href="#" onClick={() => this.showGladlyChat()} className="btn-contact-info"><img src="/img/landing-pages/types-of-cbd-oil/chat-icon.svg" alt="chat"/> Chat Now</a>
                  <a href="mailto:ask@honestpaws.com" className="btn-contact-info"><img src="/img/landing-pages/types-of-cbd-oil/email-icon.svg" alt="email"/> Email Us</a>
                  <a href="tel:1-844-729-7790" className="btn-contact-info"><img src="/img/landing-pages/types-of-cbd-oil/phone-icon.svg" alt="call us"/> Call Us</a>
                </div>
                <div className="col-12 d-none d-md-flex d-lg-none align-items-start mt-4 flex-column">
                  <a href="#convo-starter-template" className="btn-contact-info tablet"><img src="/img/landing-pages/types-of-cbd-oil/chat-icon.svg" alt="chat"/> Chat Now</a>
                  <a href="mailto:ask@honestpaws.com" className="btn-contact-info tablet mt-2"><img src="/img/landing-pages/types-of-cbd-oil/email-icon.svg" alt="email"/> Email Us</a>
                  <a href="tel:1-844-729-7790" className="btn-contact-info tablet mt-2"><img src="/img/landing-pages/types-of-cbd-oil/phone-icon.svg" alt="call us"/> Call Us</a>
                </div>
              </div>

              <div className="col-12 d-md-none pl-0 mt-3">
                <img src="/img/landing-pages/types-of-cbd-oil/M-dog-img.png" alt="have a question" className="d-md-none d-lg-block" />
              </div>

            </div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
  query TypesOfCbdOilsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    imageCalmCbdOil: file(relativePath: { eq: "landing-pages/calm-types.png" }) {
      childImageSharp {
        fluid (maxWidth: 188, maxHeight: 283) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageReliefCbdOil: file(relativePath: { eq: "landing-pages/relief-types.png" }) {
      childImageSharp {
        fluid (maxWidth: 188, maxHeight: 283) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageMobilityCbdOil: file(relativePath: { eq: "landing-pages/mobility-types.png" }) {
      childImageSharp {
        fluid (maxWidth: 188, maxHeight: 283) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageWellnessCbdOil: file(relativePath: { eq: "landing-pages/wellness-types.png" }) {
      childImageSharp {
        fluid (maxWidth: 188, maxHeight: 283) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageWellnessCbdOilExtraStrength: file(relativePath: { eq: "landing-pages/wellness-extra-strength-types.png" }) {
      childImageSharp {
        fluid (maxWidth: 188, maxHeight: 284) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <TypesOfCbdOils {...props} data={data}/>
  )
}
